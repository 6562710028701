import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Import core Swiper styles
import styles from "./modules/ProductSlider.module.scss";

const ProductSlider = ({ sliderTitle }) => {
  const sliderItems = [
    { id: 1, title: "Item 1", image: "/images/ProductsPage/beds.jpg" },
    { id: 2, title: "Item 2", image: "/images/ProductsPage/beds.jpg" },
    { id: 3, title: "Item 3", image: "/images/ProductsPage/beds.jpg" },
    { id: 4, title: "Item 4", image: "/images/ProductsPage/beds.jpg" },
    { id: 5, title: "Item 5", image: "/images/ProductsPage/beds.jpg" },
    { id: 6, title: "Item 6", image: "/images/ProductsPage/beds.jpg" },
    { id: 7, title: "Item 7", image: "/images/ProductsPage/beds.jpg" },
  ];

  return (
    <div className={styles.sliderBlock}>
      <h2 className={styles.sliderTitle}>{sliderTitle}</h2>

      <Swiper
        spaceBetween={0} // Space between slides
        slidesPerView="auto" // Allows slides to respect their own width
        breakpoints={{
          768: {
            slidesPerView: 2, // 2 items on mobile screens
          },
          1200: {
            slidesPerView: 3, // 3 items on larger screens
          },
        }}
        className={styles.swiper}
      >
        {sliderItems.map((item) => (
          <SwiperSlide key={item.id} className={styles.swiperSlide}>
            <div className={styles.sliderItem}>
              <img
                src={item.image}
                alt={item.title}
                className={styles.sliderImage}
              />
              <div className={styles.sliderItemTitle}>{item.title}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductSlider;
