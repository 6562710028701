import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import styles from "./modules/ProductGrid.module.scss";

const ProductGrid = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const products = [
    {
      id: 1,
      label: "Mattresses",
      image: "/images/ProductsPage/mattresses.jpg",
      path: "/product-category/mattresses", // Add a path for each product
    },
    {
      id: 2,
      label: "Pillows, pillow cases & sheets",
      image: "/images/ProductsPage/pillows.jpg",
      path: "/product-category/pillows-pillow-cases-and-sheets", // Add a path for each product
    },
    {
      id: 3,
      label: "Bedsheets and protectors",
      image: "/images/ProductsPage/bedsheets.jpg",
      path: "/product-category/bedsheets-and-protectors", // Add a path for each product
    },
    {
      id: 4,
      label: "Beds",
      image: "/images/ProductsPage/beds.jpg",
      path: "/product-category/beds", // Add a path for each product
    },
  ];

  // Handler function for navigating to the product page
  const handleProductClick = (path) => {
    navigate(path); // Redirect to the provided path
  };

  return (
    <div className={styles.gridContainer}>
      {products.map((product) => (
        <div
          key={product.id}
          className={styles.productBox}
          onClick={() => handleProductClick(product.path)} // Set click handler
        >
          <img
            src={product.image}
            alt={product.label}
            className={styles.image}
          />
          <div className={styles.label}>
            <p>{product.label}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductGrid;
