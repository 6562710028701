// src/components/molecules/ProductCard/ProductCard.js
import React, { useState } from "react";
import styles from "./modules/ProductCard.module.scss";

const ProductCard = ({ name, mainImage }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={styles.card}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={mainImage} alt={name} className={styles.image} />

      {/* Product Name, visible on hover */}
      <div className={`${styles.cardName} ${isHovered ? styles.show : ""}`}>
        <span>{name}</span>
      </div>
    </div>
  );
};

export default ProductCard;
