// src/components/organisms/ProductGrid/ProductGrid.js
import React, { useEffect } from "react";
import styles from "./modules/ProductGrid.module.scss";
import ProductCard from "../../molecules/ProductCard/ProductCard"; // Import the ProductCard component
import mockData from "../../../data/MockData"; // Import mock data
import { slugify } from "../../utils/slugify"; // Import the slugify utility function

const ProductGrid = ({
  selectedCategory,
  selectedSubCategory,
  setResultsCount,
}) => {
  // Filter products based on category and subcategory
  const filteredProducts = mockData.filter((product) => {
    // Check for category match
    const matchesCategory = selectedCategory
      ? slugify(product.category) === selectedCategory
      : true;

    // Check for subcategory match
    const matchesSubCategory = selectedSubCategory
      ? slugify(product.subcategory) === selectedSubCategory
      : true;

    // Product should match both category and subcategory
    return matchesCategory && matchesSubCategory;
  });

  // Update the results count whenever the filtered products change
  useEffect(() => {
    setResultsCount(filteredProducts.length);
  }, [filteredProducts.length, setResultsCount]);

  return (
    <div className={styles.grid}>
      {filteredProducts.map((product, index) => (
        <ProductCard
          key={index}
          name={product.name}
          mainImage={product.mainImage} // Use the main image from mock data
        />
      ))}
    </div>
  );
};

export default ProductGrid;
