import React from "react";
import styles from "./modules/ProductsPage.module.scss";
import ProductGrid from "../../molecules/ProductGrid/ProductGrid";
import ProductSlider from "../../molecules/ProductSlider/ProductsSlider";

function ProductsPage() {
  return (
    <div className={styles.productsPage}>
      {/* First Block */}
      <div className={styles.firstBlock}>
        <h1>Our Products</h1>
      </div>

      {/* Second Block - Product Grid */}
      <div className={styles.secondBlock}>
        <ProductGrid />
      </div>

      {/* Third Block */}
      <div className={styles.blockContainer}>
        <div className={styles.block}>
          <img
            src="/images/ProductsPage/natural.svg"
            alt="natural materials"
            className={styles.image}
          />
          <h2 className={styles.title}>Natural materials</h2>
          <p className={styles.text}>
            Our Biolatex is 100% organic and
            <br /> sustainable
          </p>
        </div>
        <div className={styles.divider}></div> {/* Vertical/Horizontal Line */}
        <div className={styles.block}>
          <img
            src="/images/ProductsPage/local.svg"
            alt="Locally sourced"
            className={styles.image}
          />
          <h2 className={styles.title}>Locally sourced</h2>
          <p className={styles.text}>
            We source the best materials and turn
            <br /> them into the finished products you know
            <br /> and love in our Sin El Fil facility.
          </p>
        </div>
        <div className={styles.divider}></div> {/* Vertical/Horizontal Line */}
        <div className={styles.block}>
          <img
            src="/images/ProductsPage/unique.svg"
            alt="Unique sales guidance"
            className={styles.image}
          />
          <h2 className={styles.title}>Unique sales guidance</h2>
          <p className={styles.text}>
            With over 30 years of experience, our sleep
            <br /> experts are here to help you find the
            <br /> perfect sleep apparel for your needs.
          </p>
        </div>
      </div>

      {/* Slider Block */}
      <ProductSlider sliderTitle={"Mattresses"} />
      <ProductSlider sliderTitle={"Pillows"} />
      <ProductSlider sliderTitle={"Bedsheets & protectors"} />
    </div>
  );
}

export default ProductsPage;
