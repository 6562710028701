import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./modules/BreadcrumbMolecule.module.scss";
import { deslugify } from "../../utils/desulgify";

const BreadcrumbMolecule = ({ category, subCategory, resetSubCategory }) => {
  const navigate = useNavigate();

  // Handlers for navigation
  const handleHomeClick = () => {
    navigate("/"); // Redirect to homepage
  };

  const handleCategoryClick = () => {
    resetSubCategory(); // Reset the subCategory when category is clicked
    navigate(`/product-category/${category}`); // Redirect to the category page
  };

  return (
    <div className={styles.breadcrumbMolecule}>
      {/* Home link */}
      <span className={styles.breadcrumbItem} onClick={handleHomeClick}>
        Home
      </span>
      <span className={styles.breadcrumbSeparator}>/</span>

      {/* Category link */}
      <span
        className={
          subCategory ? styles.breadcrumbItem : styles.breadcrumbActive
        }
        onClick={handleCategoryClick}
        style={{ cursor: "pointer" }}
      >
        {deslugify(category)} {/* Deslugify the category for display */}
      </span>

      {/* SubCategory - only show if subCategory is selected */}
      {subCategory && (
        <>
          <span className={styles.breadcrumbSeparator}>/</span>
          <span className={styles.breadcrumbActive}>
            {deslugify(subCategory)}{" "}
            {/* Deslugify the subcategory for display */}
          </span>
        </>
      )}
    </div>
  );
};

export default BreadcrumbMolecule;
