import React, { useState, useEffect } from "react";
import { slugify } from "../../utils/slugify";
import styles from "./modules/CategoryListOrganism.module.scss";

const categories = [
  { name: "Beds", subcategories: [] },
  {
    name: "Bedsheets and protectors",
    subcategories: ["Bedsheets", "Protectors"],
  },
  {
    name: "Mattresses",
    subcategories: ["Memory Foam", "Latex", "Hybrid", "Spring"],
  },
  {
    name: "Pillows, pillow case & sheets",
    subcategories: ["Pillows", "Pillow Cases"],
  },
];

const CategoryListOrganism = ({
  selectedCategory,
  selectedSubCategory,
  setCategory,
  setSubCategory,
}) => {
  const [expandedCategories, setExpandedCategories] = useState({});
  // Automatically expand the relevant category based on selectedCategory (from URL or UI)
  useEffect(() => {
    if (selectedCategory) {
      const selectedCategoryIndex = categories.findIndex(
        (category) => slugify(category.name) === selectedCategory
      );
      if (selectedCategoryIndex !== -1) {
        setExpandedCategories((prev) => ({
          ...prev,
          [selectedCategoryIndex]: true, // Expand the selected category
        }));
      }
    }
  }, [selectedCategory]);

  // Handle category toggle (expand/collapse)
  const toggleCategory = (categoryName, index) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle expanded state
    }));
    setCategory(categoryName); // Update selected category
    setSubCategory(null); // Reset subcategory when switching categories
  };

  // Handle subcategory click and update the state + URL
  const handleSubCategoryClick = (categoryName, subCategoryName) => {
    setCategory(categoryName); // Keep the category selected
    setSubCategory(categoryName, subCategoryName); // Update subcategory
  };

  return (
    <div>
      <h3>Browse</h3>
      <div>
        {categories.map((category, index) => (
          <div key={index} className={styles.categoryContainer}>
            <div
              className={`${styles.categoryName} ${
                slugify(category.name) === selectedCategory
                  ? styles.selected
                  : ""
              }`}
              onClick={() => toggleCategory(category.name, index)}
            >
              {category.name}
            </div>

            {/* Show subcategories if the category is expanded */}
            {expandedCategories[index] && category.subcategories.length > 0 && (
              <div className={styles.subCategoryList}>
                {category.subcategories.map((sub, subIndex) => (
                  <div
                    key={subIndex}
                    className={`${styles.subCategoryItem} ${
                      slugify(sub) === selectedSubCategory
                        ? styles.selected
                        : ""
                    }`}
                    onClick={() => handleSubCategoryClick(category.name, sub)}
                  >
                    {sub}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryListOrganism;
