import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import BreadcrumbMolecule from "../../molecules/BreadCrumb/BreadcrumbMolecule";
import CategoryListOrganism from "../../organisms/CategoryList/CategoryListOrganism";
import ProductGrid from "../../organisms/ProductGrid/ProductGrid";
import { slugify } from "../../utils/slugify";
import styles from "./modules/ProductListingPage.module.scss";

const ProductListingPage = () => {
  const { category: categoryFromURL, subcategory: subCategoryFromURL } =
    useParams();
  const [selectedCategory, setSelectedCategory] = useState(
    slugify(categoryFromURL || "")
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    slugify(subCategoryFromURL || "")
  );
  const [resultsCount, setResultsCount] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Control drawer visibility for mobile
  const navigate = useNavigate();

  // Sync the selected category and subcategory from the URL
  useEffect(() => {
    setSelectedCategory(slugify(categoryFromURL || ""));
    setSelectedSubCategory(slugify(subCategoryFromURL || ""));
  }, [categoryFromURL, subCategoryFromURL]);

  // Handle category selection and update URL
  const handleCategoryChange = (category) => {
    if (category) {
      const categorySlug = slugify(category);
      setSelectedCategory(categorySlug);
      setSelectedSubCategory(null);
      navigate(`/product-category/${categorySlug}`);
      setIsDrawerOpen(false); // Close the drawer on mobile when a selection is made
    }
  };

  // Handle subcategory selection and update URL
  const handleSubCategoryChange = (category, subCategory) => {
    if (category && subCategory) {
      const categorySlug = slugify(category);
      const subCategorySlug = slugify(subCategory);
      setSelectedCategory(categorySlug);
      setSelectedSubCategory(subCategorySlug);
      navigate(`/product-category/${categorySlug}/${subCategorySlug}`);
      setIsDrawerOpen(false); // Close the drawer on mobile when a selection is made
    }
  };

  return (
    <div className={styles.productListingPage}>
      {/* Breadcrumb and Results Count */}
      <div className={styles.headerRow}>
        <BreadcrumbMolecule
          category={categoryFromURL}
          subCategory={subCategoryFromURL}
          resetSubCategory={() => setSelectedSubCategory(null)}
        />
        <div className={styles.resultsCount}>
          Showing all {resultsCount} results
        </div>
      </div>

      {/* Filter Button for Mobile */}
      <button
        className={styles.filterButton}
        onClick={() => setIsDrawerOpen(true)}
      >
        Filter
      </button>

      {/* Category Drawer for Mobile */}
      {isDrawerOpen && (
        <div className={styles.drawer}>
          <button
            className={styles.closeButton}
            onClick={() => setIsDrawerOpen(false)}
          >
            &times;
          </button>
          <CategoryListOrganism
            selectedCategory={selectedCategory}
            selectedSubCategory={selectedSubCategory}
            setCategory={handleCategoryChange}
            setSubCategory={handleSubCategoryChange}
          />
        </div>
      )}

      {/* Main Content */}
      <div className={styles.productListingPageContainer}>
        <div className={styles.leftSidebar}>
          <CategoryListOrganism
            selectedCategory={selectedCategory}
            selectedSubCategory={selectedSubCategory}
            setCategory={handleCategoryChange}
            setSubCategory={handleSubCategoryChange}
          />
        </div>

        <div className={styles.rightSidebar}>
          <ProductGrid
            selectedCategory={selectedCategory}
            selectedSubCategory={selectedSubCategory}
            setResultsCount={setResultsCount}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductListingPage;
