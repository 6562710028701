// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Layout from "./components/layout/Layout";
import HomePage from "./components/organisms/HomePage/HomePage";
import OurMaterials from "./components/pages/OurMaterials/OurMaterials";
import AboutNAM from "./components/pages/AboutNAM/AboutNAM";
import Hospitality from "./components/pages/Business/Hospitality/Hospitality";
import Medical from "./components/pages/Business/Medical/Medical";
import SpecialCare from "./components/pages/Business/SpecialCare/SpecialCare";
import ContactUs from "./components/pages/ContactUs/ContactUs";
import ProductsPage from "./components/pages/ProductsPage/ProductsPage";
import ProductListingPage from "./components/pages/ProductListing/ProductListingPage";

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Ensure ScrollToTop is applied globally */}
      <Routes>
        {/* Routes that should use the Layout with Header and Footer */}
        <Route
          path="/"
          element={
            <Layout>
              <HomePage />
            </Layout>
          }
        />
        <Route
          path="/our-materials"
          element={
            <Layout>
              <OurMaterials />
            </Layout>
          }
        />
        <Route
          path="/about-nam"
          element={
            <Layout>
              <AboutNAM />
            </Layout>
          }
        />
        <Route
          path="/for-the-hospitality-business"
          element={
            <Layout>
              <Hospitality />
            </Layout>
          }
        />

        <Route
          path="/for-medical-uses"
          element={
            <Layout>
              <Medical />
            </Layout>
          }
        />

        <Route
          path="/for-special-care"
          element={
            <Layout>
              <SpecialCare />
            </Layout>
          }
        />

        <Route
          path="/contact-us"
          element={
            <Layout>
              <ContactUs />
            </Layout>
          }
        />

        <Route
          path="/our-products"
          element={
            <Layout>
              <ProductsPage />
            </Layout>
          }
        />

        {/* Add route to handle both category and subcategory */}
        <Route
          path="/product-category/:category/:subcategory"
          element={
            <Layout>
              <ProductListingPage />
            </Layout>
          }
        />
        {/* This route handles category without subcategory */}
        <Route
          path="/product-category/:category"
          element={
            <Layout>
              <ProductListingPage />
            </Layout>
          }
        />

        {/* Route that should not use the Layout (e.g., Admin Portal) */}
        {/* <Route path="/admin" element={<AdminPortal />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
