const products = [
  // Beds Category
  {
    name: "Luxury Wooden Bed",
    description: "A premium wooden bed crafted with high-quality oak.",
    additionalInformation: "Dimensions: 200x180cm. Material: Oak wood.",
    category: "Beds",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/beds/luxury-wooden-bed-1.jpg",
      "/images/beds/luxury-wooden-bed-2.jpg",
    ],
  },
  {
    name: "Modern Metal Frame Bed",
    description: "A sleek, modern bed with a sturdy metal frame.",
    additionalInformation: "Dimensions: 190x160cm. Material: Stainless steel.",
    category: "Beds",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/beds/modern-metal-bed-1.jpg",
      "/images/beds/modern-metal-bed-2.jpg",
    ],
  },

  // Bedsheets and Protectors
  {
    name: "Cotton Bedsheets",
    description: "High-quality cotton bedsheets with a soft touch.",
    additionalInformation: "Material: 100% cotton. Color: White.",
    category: "Bedsheets and protectors",
    subcategory: "Bedsheets",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/bedsheets/cotton-bedsheet-1.jpg",
      "/images/bedsheets/cotton-bedsheet-2.jpg",
    ],
  },
  {
    name: "Silk Bedsheets",
    description: "Luxury silk bedsheets with a smooth finish.",
    additionalInformation: "Material: 100% silk. Color: Champagne.",
    category: "Bedsheets and protectors",
    subcategory: "Bedsheets",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/bedsheets/silk-bedsheet-1.jpg",
      "/images/bedsheets/silk-bedsheet-2.jpg",
    ],
  },
  {
    name: "Waterproof Mattress Protector",
    description: "Protect your mattress with a waterproof protector.",
    additionalInformation: "Material: Polyester. Fit: Queen size.",
    category: "Bedsheets and protectors",
    subcategory: "Protectors",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/protectors/waterproof-mattress-protector-1.jpg",
      "/images/protectors/waterproof-mattress-protector-2.jpg",
    ],
  },
  {
    name: "Anti-Allergy Mattress Protector",
    description: "Anti-allergy mattress protector for sensitive skin.",
    additionalInformation: "Material: Cotton blend. Fit: King size.",
    category: "Bedsheets and protectors",
    subcategory: "Protectors",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/protectors/anti-allergy-mattress-protector-1.jpg",
      "/images/protectors/anti-allergy-mattress-protector-2.jpg",
    ],
  },

  // Mattresses Category
  {
    name: "Memory Foam Mattress",
    description: "High-density memory foam mattress for superior comfort.",
    additionalInformation: "Thickness: 10 inches. Size: Queen.",
    category: "Mattresses",
    subcategory: "Memory Foam",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/mattresses/memory-foam-mattress-1.jpg",
      "/images/mattresses/memory-foam-mattress-2.jpg",
    ],
  },
  {
    name: "Latex Mattress",
    description: "Natural latex mattress for a firm, supportive feel.",
    additionalInformation: "Thickness: 12 inches. Size: King.",
    category: "Mattresses",
    subcategory: "Latex",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/mattresses/latex-mattress-1.jpg",
      "/images/mattresses/latex-mattress-2.jpg",
    ],
  },
  {
    name: "Hybrid Mattress",
    description: "Hybrid mattress combining memory foam and spring coils.",
    additionalInformation: "Thickness: 11 inches. Size: Queen.",
    category: "Mattresses",
    subcategory: "Hybrid",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/mattresses/hybrid-mattress-1.jpg",
      "/images/mattresses/hybrid-mattress-2.jpg",
    ],
  },
  {
    name: "Spring Mattress",
    description: "Traditional spring mattress for a firm sleep surface.",
    additionalInformation: "Thickness: 8 inches. Size: Full.",
    category: "Mattresses",
    subcategory: "Spring",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/mattresses/spring-mattress-1.jpg",
      "/images/mattresses/spring-mattress-2.jpg",
    ],
  },

  // Pillows, Pillow Cases & Sheets
  {
    name: "Feather Pillow",
    description: "Soft feather pillow for ultimate comfort.",
    additionalInformation: "Size: Standard. Material: Feather.",
    category: "Pillows, pillow case & sheets",
    subcategory: "Pillows",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/pillows/feather-pillow-1.jpg",
      "/images/pillows/feather-pillow-2.jpg",
    ],
  },
  {
    name: "Memory Foam Pillow",
    description: "Memory foam pillow for neck support and comfort.",
    additionalInformation: "Size: King. Material: Memory foam.",
    category: "Pillows, pillow case & sheets",
    subcategory: "Pillows",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/pillows/memory-foam-pillow-1.jpg",
      "/images/pillows/memory-foam-pillow-2.jpg",
    ],
  },
  {
    name: "Silk Pillowcase",
    description: "Luxury silk pillowcase for a smooth, soft feel.",
    additionalInformation: "Material: Silk. Size: Standard.",
    category: "Pillows, pillow case & sheets",
    subcategory: "Pillow Cases",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/pillowcases/silk-pillowcase-1.jpg",
      "/images/pillowcases/silk-pillowcase-2.jpg",
    ],
  },
  {
    name: "Cotton Pillowcase",
    description: "Breathable cotton pillowcase for a cool night's sleep.",
    additionalInformation: "Material: Cotton. Size: King.",
    category: "Pillows, pillow case & sheets",
    subcategory: "Pillow Cases",
    mainImage: "/images/ProductsPage/beds.jpg",
    secondaryImages: [
      "/images/pillowcases/cotton-pillowcase-1.jpg",
      "/images/pillowcases/cotton-pillowcase-2.jpg",
    ],
  },
];

export default products;
